import { Flex, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAuth } from '@/hooks/AuthContext';

export default function IndexPage() {
  const { loginStatus, user, hasPermission } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (loginStatus !== 'LOGGED_IN') {
      router.push('/login');
    } else if (loginStatus === 'LOGGED_IN') {
      switch (user.type) {
        case 'admin':
          hasPermission('reports.sensitive')
            ? router.push('/dashboard')
            : router.replace('/orders');
          break;
        case 'client':
          {
            if (user.role.canCreateOrder) router.push('/dashboard');
            else router.push('/clients/me');
          }
          break;
      }
    }
  }, [loginStatus]);

  return (
    <Flex justifyContent='center' alignItems='center'>
      <motion.div
        animate={{
          opacity: [1, 0.3, 1],
        }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      >
        <Image
          alt='Procura Logo'
          height='100'
          p='5'
          objectFit='contain'
          objectPosition='center'
          src='/images/brand-logo.png'
        />
      </motion.div>
    </Flex>
  );
}

IndexPage.getLayoutParams = () => ({
  useNewLayout: false,
  removePadding: true,
});
